.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-grid-container{
  margin-bottom: 5rem;
}

.footer-social{
  height: 4rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container-video {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 1rem;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.our-story {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
}

@media screen and (min-width: 100px) {

  .container-video {
    margin-left: 1%;
    margin-right: 1%;
  }

  .our-story {
    margin-left: 1%;
    margin-right: 1%;
    padding: 1rem;
  }


}

@media screen and (min-width: 500px) {

  .rogd-advice {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 5rem;
    padding: 25;
  }

  .our-story {
    margin-left: 1%;
    margin-right: 1%;
  }



}

@media screen and (min-width: 800px) {

  .our-story {
    margin-left: 15%;
    margin-right: 15%;
  }

  .tab-container {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 5rem;
  }
}


@media screen and (min-width: 1000px) {

  .our-story {
    margin-left: 20%;
    margin-right: 20%;
  }

  .tab-container {
    margin-left: 25%;
    margin-right: 25%;
    padding-bottom: 5rem;
  }

}

@media screen and (min-width: 1200px) {

  .our-story {
    margin-left: 25%;
    margin-right: 25%;
  }
}

.Connect {
  position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}


.tab-box {
  margin-left: .5rem;
  margin-right: .5rem;
  margin-top: 1rem;
  padding-bottom: 5rem;
}

.About{
  padding-left: 10%;
  padding-right: 10%;
}